<template>
  <div class="legende">
    <div class="inner">
      <h1>{{ $t('header.legend') }}</h1>

      <div class="bridges3-theme">
        <h2>Bridges 3 (2024)</h2>

        <div class="flexdiv">
            <div class="bridges">
                <div v-for="bridge in bridges3" :key="bridge.teken" v-bind:class="{ bridge: true, clickable: bridge.infoPage != undefined }" @click="bridge.infoPage ? goToInfoPage(bridge) : undefined">
                    <div class="circle">{{ bridge.teken }}</div>
                    <span class="creator">{{ bridge.creator }}</span><span class="address">{{ ", " + bridge.address }}</span>
                </div>
            </div>
        </div>

        <a href="https://thebridges.be/downloads/bridges3-map-full.pdf" target="_blank" class="btnDownloadMap">Download Map</a>
      </div>

      <div class="seabridges-theme">
        <h2>Seabridges</h2>

        <div class="flexdiv">
            <div class="bridges">
                <div v-for="bridge in seabridges" :key="bridge.teken" v-bind:class="{ bridge: true, clickable: bridge.infoPage != undefined }" @click="bridge.infoPage ? goToInfoPage(bridge) : undefined">
                    <div class="circle">{{ bridge.teken }}</div>
                    <span class="creator">{{ bridge.creator }}</span><span class="address">{{ ", " + bridge.address }}</span>
                </div>
            </div>
        </div>

        <a href="https://thebridges.be/downloads/seabridges-map-full.png" target="_blank" class="btnDownloadMap">Download Map</a>
      </div>

      <div class="bridges-theme">
        <h2>Bridges</h2>

        <div class="flexdiv">
            <div class="bridges">
                <div v-for="bridge in bridges" :key="bridge.teken" v-bind:class="{ bridge: true, clickable: bridge.infoPage != undefined }" @click="bridge.infoPage ? goToInfoPage(bridge) : undefined">
                    <div class="circle">{{ bridge.teken }}</div>
                    <span class="creator">{{ bridge.creator }}</span><span class="address">{{ ", " + bridge.address }}</span>
                </div>
            </div>

            <div class="muren">
                <div v-for="muur in muren" :key="muur.teken" v-bind:class="{ muur: true, clickable: muur.infoPage != undefined }" @click="muur.infoPage ? goToInfoPage(muur) : undefined">
                    <div class="circle">{{ muur.teken }}</div>
                    <span class="creator">{{ muur.creator }}</span><span class="address">{{ ", " + muur.address }}</span>
                </div>
            </div>
        </div>

        <a href="https://thebridges.be/downloads/map.jpg" target="_blank" class="btnDownloadMap">Download Map</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Legende',
  computed: {
    muren() {
      return this.getMuren();
    },
    bridges() {
      return this.getBridges();
    },
    seabridges() {
      return this.getSeabridges();
    },
    bridges3() {
      return this.getBridges3();
    },
  },
  methods: {
    ...mapGetters(["getMuren", "getBridges", "getSeabridges", "getBridges3"]),
    goToInfoPage(poi) {
      var imageIndex = poi.imageIndex ? "&imageIndex=" + poi.imageIndex : "";

      this.$router.push({ path: `/info?name=${poi.infoPage}${imageIndex}` });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/sass/libs/_mixins.scss";
  @import "@/assets/sass/libs/_vars.scss";
  

  .legende {
    background: $sand;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  
  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    flex: 1;
    max-width: 1400px;
    margin: auto;

    h1, h2 {
      color: $red;
      font-family: "DIN-Condensed-Bold", sans-serif;
    }
  }

  .flexdiv {
    display: flex;
    flex-direction: column;

    @include desktop {
      flex-direction: row;
    }
  }

  .muren, .bridges {
    margin: 10px 0;

    @include desktop {
      margin-right: 40px;
    }

    .muur, .bridge {
      display: flex;
      align-items: center;
      font-family: "DIN-Condensed-Bold", sans-serif;
      font-weight: bolder;
      margin-top: 12px;
      cursor: default;

      &.clickable {
        cursor: pointer;
      }

      .circle {
        width: 25px;
        height: 25px;
        border-radius: 12.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        margin-right: 10px;
        padding-top: 5px;
      }

      .creator {
        text-transform: uppercase;
      }

      .creator, .address {
        padding-top: 5px;
      }
    }
  }

  .bridges3-theme {
    margin-top: 25px;

    .bridge {
      color: $lightblue;

      .circle {
        background-color: $blue;
        color: white;
      }
    }
  }

  .seabridges-theme {
    margin-top: 25px;

    .bridge {
      color: #cc3472;

      .circle {
        background-color: #5d8aa7;
        color: white;
      }
    }
  }

  .bridges-theme {
    margin-top: 50px;

    .muur, a {
      color: #05019E;

      .circle {
        background-color: #05019E;
        color: white;
      }
    }

    .bridge, h2 {
      color: #E40605;

      .circle {
        background-color: #42a8df;
        color: #E40605;
      }
    }
  }


  .btnDownloadMap {
    display: block;
    font-family: "DIN-Condensed-Bold", sans-serif;
    color: $blue;
    text-transform: uppercase;
    font-weight: bolder;
    text-decoration: underline;
    margin-top: 20px;
  }
</style>